export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "出库通知单号",
    dataIndex: "delivery_order_number",
    fixed: "left",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 240,
  },
  {
    title: "包装规格",
    dataIndex: "material_spec",
    width: 120,
    ellipsis: true,
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "出库数量",
    dataIndex: "total_quantity",
    width: 100,
    ellipsis: true,
    sorter: true,
  },
  {
    title: "产品单价",
    dataIndex: "material__unit_price",
    width: 120,
    ellipsis: true,
    sorter: true,
    customRender: (value, item) => item.material_unit_price,
  },
  {
    title: "序列号",
    dataIndex: "serial_number",
    width: 240,
    ellipsis: true,
  },
  {
    title: "单据类型",
    dataIndex: "order_type_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "预计到货日期",
    dataIndex: "arrival_date",
    width: 120,
    ellipsis: true,
  },
  {
    title: "收货人",
    dataIndex: "customer_contact",
    width: 120,
    ellipsis: true,
  },
  {
    title: "收货地址",
    dataIndex: "receiving_address",
    width: 240,
    ellipsis: true,
  },
];
